<template>
  <div id="NumberOfReplies">
    <CSBreadcrumb/>
    <div class="filter-panel" style="padding: 15px 30px;">
      <input type="text" class="filter-panel-input" placeholder="搜索姓名/手机号/内容" v-model="search">
      <button
          type="button"
          class="btn btn-primary"
          style="width: 50px; height: 30px; padding: 0; margin-bottom: 0;"
          @click="queryNumberOfReplies()"
      >
          查询
      </button>
    </div>
    <div class="result-panel">
        <CSTable :thead-top="60">
            <template v-slot:thead>
                <tr>
                    <th>回复时间</th>
                    <th>姓名</th>
                    <th>手机号</th>
                    <th>内容</th>
                    <th>操作</th>
                </tr>
            </template>
            <template v-slot:tbody>
                <tr
                    v-for="replyNumberItem in replyNumberlist"
                    :key="replyNumberItem.id"
                >
                    <td class="date-td">{{replyNumberItem.createIime}}</td>
                    <td>{{replyNumberItem.userName}}</td>
                    <td>{{replyNumberItem.userPhone}}</td>
                    <td>{{replyNumberItem.content}}</td>
                    <td>
                        <div class="btn-group">
                            <button
                                type="button"
                                class="btn btn-primary dropdown-toggle"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                            >
                                操作
                            </button>
                            <ul
                                class="dropdown-menu"
                                style="width: 86px;"
                            >
                                <li>
                                    <a
                                        @click="
                                confirmationDelete = true;
                                replyId = replyNumberItem.id;
                            "
                                        style="width: 100%;"
                                    >删除</a
                                    >
                                </li>
                                <li>
                                    <a
                                        @click="
                                bannedToPost(
                                    replyNumberItem.userId
                                )
                            "
                                        style="width: 100%;"
                                    >禁言</a
                                    >
                                </li>
                            </ul>
                        </div>
                    </td>
                </tr>
            </template>
        </CSTable>
    </div>
    <!-- 二次提示是否删除的弹出框 -->
    <CSDialog
        dialog-header-class=" " dialog-confirm-btn-text="确定"
        dialogTitle="删除回复"
        :dialogVisible="confirmationDelete"
        dialogWidth="720px"
        @onClose="confirmationDelete = false;"
        @onConfirm="deleteInvitation"
    >
        <div
            slot="dialog-content"
            style="padding: 20px 30px;"
        >
            <div class="banned_title">删除原因</div>
            <div style="width: 430px; display: inline-block;">
                <div
                    v-for="(item, index) in bannedsList"
                    :key="index"
                    :class="[
                        'banned_list',
                        {
                            active: checkedDeleteCauseArr.includes(item),
                        },
                    ]"
                    style="margin-bottom: 23px;"
                    @click="changeCheckedDeleteCause(item)"
                >
                    {{ item }}
                </div>
            </div>
        </div>
    </CSDialog>
    <!-- 点击预警禁言时的弹出框 -->
        <CSDialog
            dialogTitle="禁言用户"
            dialogWidth="720px"
            :dialogVisible="bannedOptions"
            dialogConfirmBtnText="确定"
            @onClose="bannedOptions = false"
            @onConfirm="warningDetermineBannedToPost"
        >
            <div
                slot="dialog-content"
                style="padding: 20px 30px;"
            >
                <div>
                    <div class="banned_title">禁言原因</div>
                    <div style="width: 430px; display: inline-block">
                        <div
                            v-for="(bannedListItem, index) in bannedsList"
                            :key="index"
                            :class="[
                                'banned_list',
                                { active: isActive.includes(bannedListItem) },
                            ]"
                            @click="showdifference(bannedListItem)"
                            style="margin-bottom: 23px; line-height: 35px;"
                        >
                            {{ bannedListItem }}
                        </div>
                    </div>
                </div>
                <div class="box_fontsize">
                    <span class="lableBox" style="vertical-align: middle;">禁言时长</span>
                    <CSSelect
                        iWidth="36px"
                        height="40px"
                        style="width: 200px; font-size: 24px;"
                    >
                        <select
                            v-model="BannedTime"
                            :style="{
                                color: BannedTime != '' ? '#000' : '#999',
                            }"
                        >
                            <option value="">请选择</option>
                            <option style="color: #000" :value="-1">永久</option>
                            <template v-for="n in 30">
                                <option
                                    :key="n"
                                    :value="n"
                                    :disabled="BannedTime != ''"
                                    style="color: #000"
                                >
                                    {{ n }}天
                                </option>
                            </template>
                        </select>
                    </CSSelect>
                </div>
            </div>
        </CSDialog>
  </div>
</template>

<script>
import CSBreadcrumb from "@/components/common/CSBreadcrumb";
import CSDialog from "@/components/common/CSDialog";
import CSSelect from "@/components/common/CSSelect";
import {
  adminQueryReplyUrl,
  adminDelReplyUrl,
  adminBlockUrl
} from "@/requestUrl"
import CSTable from "@/components/common/CSTable";

export default {
  name: "NumberOfReplies",
  props: {
    id: Number,
    deleteInvitationId: Number,
  },
  components: {
      CSTable,
    CSDialog,
    CSBreadcrumb,
    CSSelect,
  },
  data() {
    return {
      search: "",
      // 回复数列表
      replyNumberlist: [],
      confirmationDelete: false,
      bannedOptions: false,
      replyId: '',
      currentItemInvitationId: '',
      // 禁言列表的数据
      bannedsList: [
        "涉及敏感、反动等不良信息",
        "无实意字符或乱码等特征的灌水类文本",
        "低俗辱骂的垃圾文本内容",
        "含有推广意向的内容",
        "不合网络规范的淫秽内容",
        "不合国家法规的暴恐、赌博毒品等违法内容",
      ],
      checkedDeleteCauseArr: [],
      isActive: [],
      BannedTime: '',
    }
  },
  created() {
    this.queryNumberOfReplies();
  },
  methods: {
    // 查询回复人数
    async queryNumberOfReplies() {
      let res = await this.$fly.post(adminQueryReplyUrl, {
          regionCode: this.$vc.getCurrentRegion().code, //区域code  必填
          id: this.id, //帖子id  必填
          search: this.search, //搜索
      })
      if (res.code != 0) {
        return
      }
      this.replyNumberlist = res.data || [];
    },
    // 删除回复
    async deleteInvitation(id) {
      let res = await this.$fly.post(adminDelReplyUrl,{
          regionCode: this.$vc.getCurrentRegion().code, //区域code 必填
          id: this.replyId, //回复id 必填
          isDelete: 0, //0删除1未删除 必填
          postId: this.deleteInvitationId,
          deleteCause: this.checkedDeleteCauseArr.join('；'),
          userId: this.$vc.getCurrentStaffInfo().id,
      })
      if (res.code != 0) {
        return;
      }
      this.confirmationDelete = false;
      this.checkedDeleteCauseArr = [];
      this.queryNumberOfReplies();
    },
    /**
     * 添加或者移除删除原因
     * @param {String} cause 原因
     *  */
    changeCheckedDeleteCause(cause) {
      const index = this.checkedDeleteCauseArr.indexOf(cause);
      if (index > -1) {
          this.checkedDeleteCauseArr.splice(index, 1);
      } else {
          this.checkedDeleteCauseArr.push(cause);
      }
    },
    // 禁言用户
    bannedToPost(id) {
      this.bannedOptions = true;
      this.currentItemInvitationId = id;
      this.BannedTime = "";
      this.isActive = [];
    },
    // 预警确定禁言
    async warningDetermineBannedToPost() {
      let res = await this.$fly
          .post(adminBlockUrl, {
              regionCode: this.$vc.getCurrentRegion().code, //区域code 必填
              userId: this.currentItemInvitationId, //用户id 必填
              isBlock: 1, //禁言状态1禁言2.取消禁言 必填
              blockCause: this.isActive.toString(), //禁言原因
              blockDay: this.BannedTime, //禁言时长  永久-1
          })
      if (res.code != 0) {
        return
      }
      this.$vc.toast("禁言成功");
      this.bannedOptions = false;
    },
    showdifference(str) {
            const index = this.isActive.indexOf(str);
            if (index > -1) {
                this.isActive.splice(index, 1);
            } else {
                this.isActive.push(str);
            }
        },
  },
}
</script>

<style lang="stylus" scoped>
.filter-panel-input
  width 230px
  height 30px
  border 1px solid #999
  border-radius 3px
  padding-left 10px
  vertical-align: middle
  margin-right: 20px
.banned_title
    font-size 24px
    margin-right 30px
    margin-left 20px
    display inline-block
    vertical-align top
.banned_list
    width 480px
    height 40px
    font-size 24px
    color #1ab394
    border 1px solid #1ab394
    display inline-block
    border-radius 5px
    padding-left 5px
    cursor pointer
.active
    background-color #1ab394
    color #fff
.lableBox
    font-size 24px
    margin-left 20px
    margin-right 30px !important
</style>
